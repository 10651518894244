.onb1 {
  height: 22vh;
  width: 15vh;
}

.u4uOnb {
  height: 13vh;
  width: 26vh;
}

.onb1Container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vh;
}

.onb1-text {
  font-size: 21px;
  font-family: "Inter-SemiBold";
  color: var(--black);
}

.inter-bold-text-16 {
  font-size: 16px;
  font-family: "Inter-SemiBold";
  color: var(--black);
  font-weight: 500;
}

.inter-bold-text-16-white {
  font-size: 16px;
  font-family: "Inter-SemiBold";
  color: var(--white);
  font-weight: 500;
}

/* onboarding */
.CountContainer {
  background-color: var(--white);
  /* border-radius: 10px; */
  /* padding: 1px 0px; */
  /* display: flex; */
  width: 100%;
  height: 15px;
  border-color: var(--white);
  border-width: 2px;
  /* flex-direction: column; */
}

.box {
  height: 11px;
  /* width: 45px; */
}

.box-1 {
  background-color: var(--brand-pink);
}

.box-2 {
  background-color: var(--red);
}

.box-3 {
  background-color: var(--orange);
}

.box-4 {
  background-color: var(--yellow);
}

.box-5 {
  background-color: var(--green);
}

.box-6 {
  background-color: #91a8ec;
}

.box-7 {
  background-color: var(--lavender);
}

.box-8 {
  background-color: var(--purple);
}

.top-svg {
  position: absolute;
  top: 0;
  /* z-index: -1; */
}

.arrow-left {
  height: 24px;
  width: 24px;
}

.onb1-2 {
  height: 14vh;
  width: 9vh;
  transform: rotate(-3deg);
  /* top:1; */
}

.onb1-2-h {
  height: 16vh;
  width: 8vh;
  transform: rotate(-3deg);
  /* top:1; */
}

.onb1-2-h {
  height: 16vh;
  width: 8vh;
  /* transform: rotate(-5deg); */
}

.onb1-3 {
  height: 13vh;
  width: 9vh;
  /* transform: rotate(-5deg); */
}

.onb1-4 {
  height: 14vh;
  width: 8vh;
  /* transform: rotate(-3deg); */
  /* top:1; */
}

.onb1-2-m1 {
  height: 12vh;
  width: 36vh;
}

.onb9-m1 {
  height: 32vh;
  width: 35vh;
}

.info-box {
  border-radius: 20px;
  border-width: 4px;
  border-color: var(--black);
  background-color: var(--white);
  padding: 10px 0px 10px 15px;
}

.box-text {
  font-family: "Chilanka";
  font-size: 18px;
  line-height: 20px;
}

.chilanka-regular-18 {
  font-family: "Chilanka";
  font-size: 18px;
  line-height: 20px;
}

.title-text {
  font-family: "Inter-Bold";
  font-size: 20px;
  margin-bottom: 5px;
}

.title-text-16 {
  font-family: "Inter-SemiBold";
  font-size: 16px;
}

.inter-semibold-20 {
  font-family: "Inter-SemiBold";
  font-size: 20px;
}

.inter-text-14-semibold {
  font-family: "Inter-SemiBold";
  font-size: 14px;
}

.inter-text-12-semibold {
  font-family: "Inter-SemiBold";
  font-size: 12px;
}

.inter-text-9-semibold {
  font-family: "Inter-SemiBold";
  font-size: 10px;
}

.inter-text-18-semibold {
  font-family: "Inter-SemiBold";
  font-size: 18px;
}

.inter-text-16-extrabold {
  font-family: "Inter-ExtraBold";
  font-size: 16px;
}

.inter-text-20-extrabold {
  font-family: "Inter-ExtraBold";
  font-size: 20px;
}

.inter-28-bold {
  font-family: "Inter-Bold";
  font-size: 28px;
}

.inter-32-bold {
  font-family: "Inter-Bold";
  font-size: 32px;
}

.inter-24-bold {
  font-family: "Inter-Bold";
  font-size: 24px;
}

.inter-20-bold {
  font-family: "Inter-Bold";
  font-size: 20px;
}

.inter-18-bold {
  font-family: "Inter-Bold";
  font-size: 18px;
}

.inter-16-bold {
  font-family: "Inter-Bold";
  font-size: 16px;
}

.inter-12-bold {
  font-family: "Inter-Bold";
  font-size: 12px;
}

.inter-13-bold {
  font-family: "Inter-Bold";
  font-size: 13px;
}

.inter-14-bold {
  font-family: "Inter-Bold";
  font-size: 14px;
}

.sub-title-text-16 {
  font-family: "Inter-Regular";
  font-size: 16px;
}

.sub-title-text {
  font-family: "Inter-Regular";
  font-size: 14px;
}

.inter-regular-12 {
  font-family: "Inter-Regular";
  font-size: 12px;
}

.inter-regular-10 {
  font-family: "Inter-Regular";
  font-size: 10px;
}

.inter-regular-24 {
  font-family: "Inter-Regular";
  font-size: 24px;
  overflow: hidden;
}

.fjord-regular-26 {
  font-family: "FjordOne-Regular";
  font-size: 26px;
}

.fjord-regular-20 {
  font-family: "FjordOne-Regular";
  font-size: 20px;
}

.fjord-regular-16 {
  font-family: "FjordOne-Regular";
  font-size: 16px;
}

.cherry-bomb-regular-21 {
  font-family: "CherryBomb-Regular";
  font-size: 21px;
}

.btn-onb2 {
  height: 60px;
  overflow: hidden;
}

.btn-onb2-text {
  font-family: "Inter-Regular";
  font-size: 20px;
  color: var(--black);
}

.btn-onb4-text {
  font-family: "Inter-Regular";
  font-size: 14px;
  color: var(--black);
}

.btn-onb2-text-1 {
  font-family: "Inter-Regular";
  font-size: 16px;
  color: var(--black);
}

.btn-icon {
  height: 5.4vh;
  width: 5.4vh;
  overflow: hidden;
  margin-left: 5px;
}

.scrollable-container {
  max-height: 300px;
  /* Set a maximum height for the scrollable section */
  overflow: auto;
  /* Enable scrolling if content overflows the container */
}

/* .scroll-step-six-container {
  max-height: 60vh;
  overflow: auto;
} */

.ob7hi {
  height: 9vh;
  width: 9vh;
}

.ob8key {
  height: 10vh;
  width: 23vh;
}

/* door */

.pink-bg {
  background: "green";
}

.blue-bg {
  background-color: var(--blue);
}

.doorGM {
}

.DoorFrame {
  border-color: var(--black);
  border-width: 2.5px;
  /* padding: 25px 25px; */
  border-radius: 12px;
}

.DoorFrameTwo {
  /* height: 90vh;
  width:50vh; */
}

.DoorTop {
  height: 35dvh;
}

.boxDesign {
  border-color: var(--black);
  border-width: 2.5px;
  border-radius: 12px;
}

.boorlabel {
  height: 18vh;
}

.boorlabel-cont {
  top: 60px;
  right: 10px;
  left: 0px;
  /* right: 10px  */
}

.boorhandle {
  height: 6vh;
  width: 6vh;
  right: 62px;
  top: 50px;
}

.rating {
  background-color: var(--light-pink);
  padding: 4px 7px;
  border-radius: 5px;
}

.daily-check-time-icon {
  height: 4vh;
  width: 4vh;
}

.daily-check-time-icon-2 {
  height: 4vh;
  width: 3vh;
}

.dailt-check-box-filled {
  border-right-width: 3px;
  border-color: var(--black);
}

.hidden-scroll {
  overflow: hidden;
}

.user-cardheader {
  height: 3.5vh;
  width: 3.5vh;
  border-radius: 2.5vh;
  border-width: 1px;
  border-color: var(--black);
  object-fit: cover;
}

.user-cardimage {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.quiz-close {
  height: 12vh;
  width: 30vh;
}

.disable-primary {
  background-color: var(--light-pink) !important;
  border-color: var(--light-pink) !important;
  box-shadow: none !important;
}

.font-white {
  color: var(--white);
}

.font-black {
  color: var(--black);
}

.light-purple-bg {
  background-color: var(--light-purple);
}

.light-pink-bg {
  background-color: var(--light-pink);
}

.light-green-bg {
  background-color: var(--light-green);
}

.card1-bg {
  background-color: var(--card1);
}

.card2-bg {
  background-color: var(--card2);
}

.border-rad-card1 {
  border-radius: 10px;
}

.my-journey-card {
  border-color: var(--black);
  border-width: 1px;
  border-radius: 5px;
}

.width-tab1 {
  width: 20vw;
}

.profile-img {
  width: 160px;
  height: 160px;
  border-radius: 20vh;
  border-width: 2px;
  object-fit: cover;
  border-color: var(--brand-pink);
}

.tech-support-item-cont {
  background-color: var(--white);
  overflow: hidden;
  border-radius: 10vh;
  height: 13vh;
  width: 13vh;
  border-color: var(--brand-pink);
  border-bottom-width: 4px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}

.tech-support-item {
  height: 11vh;
  width: 8vh;
}

.text-heart {
  height: 10vh;
  width: 11vh;
  top: 20px;
  left: 10px;
}

.envelope {
  height: 9vh;
  width: 12vh;
  top: 20px;
  left: 10px;
}

.phone {
  height: 12vh;
  width: 10vh;
  top: 20px;
  left: 10px;
}

.points-jem-1 {
  height: 10vh;
  width: 8vh;
}

.points-jem-2 {
  height: 9vh;
  width: 8vh;
}

.mood-ring-imoji {
  height: 4vh;
  width: 4vh;
}

.mood-ring-zzz {
  height: 3vh;
  width: 3vh;
}

.mood-imoji-cont {
  border-radius: 5px;
}

.mood-list-cont {
  border-color: var(--black);
  border-width: 2px;
  border-radius: 5px;
}

.list-jem {
  height: 5vh;
  width: 5vh;
}

.mood-round-btn {
  height: 2vh;
  width: 2vh;
  border-radius: 2vh;
  border-width: 2px;
  border-color: var(--black);
}

.pie {
  width: 16vh;
  height: 16vh;
}

.chat-icon-cont {
  background-color: var(--white);
  overflow: hidden;
  border-radius: 10vh;
  height: 70px;
  width: 70px;
  border-color: var(--black);
  border-bottom-width: 4px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}
.chat-icon-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.message-chat-icon-cont {
  background-color: var(--white);
  overflow: hidden;
  border-radius: 10vh;
  height: 30px;
  width: 30px;
  border-color: var(--black);
  border-bottom-width: 4px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}
.chat-icon-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.message-chat-icon-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.message-chat-profile-item {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 30px;
  min-height: 30px;
}
.chat-profile-item {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  max-width: 70px;
  min-height: 70px;
}

.technical-support-item {
  height: 65px !important;
  width: 42px !important;
  margin-left: -9px;
  margin-top: -10px;
  max-width: none;
}

.chat-message {
  max-width: 30vh;
}

.chat-input-icon {
  height: 5vh;
  width: 5vh;
}

.profile-update-btn {
  height: 5vh;
  width: 5vh;
  background: #e1f9f9;
  border-width: 1px 2.5px 2.5px 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 50px;
}

.chat-Text-Box {
  /* Frame 1209 */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border-width: 1px 2.5px 2.5px 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.chat-Text-Box input:focus{ outline: none;}

.bottom-75 {
  bottom: 75px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  /* background: none; */
  /* display: none; */
  /* color: "gray"; */
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 120%;
  height: 120%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.mr-tp-1
{
  padding-top: 5rem;
}
.mr-tp-3
{
  padding-top: 4.5rem;
}
.readmore-btn
{
  border: 4px solid var(--brand-pink);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--brand-pink);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}