.primary-btn {
  border-radius: 100px;
  border: 4px solid var(--brand-pink);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--brand-pink);
  display: flex;
  /* min-height: 40px; */
  align-items: center;
  justify-content: center;
  /* max-width: 178px; */
  /* flex-direction: column; */
}

.secondary-btn {
  border-radius: 100px;
  border: 1px solid var(--black);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--white);
  display: flex;
  /* min-height: 40px; */
  align-items: center;
  justify-content: center;
}

.secondary-btn-sm {
  border-radius: 100px;
  border: 1px solid var(--black);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--white);
  display: flex;
  /* min-height: 40px; */
  align-items: center;
  justify-content: start;
}

.secondary-btn-input {
  border-radius: 100px;
  border: 1px solid var(--black);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--white);
  display: flex;
}

.border-bottom {
  border-bottom-color: var(--black);
}

.secondary-btn-active {
  background-color: var(--black);
  /* justify-content: right; */
  padding-left: 7vw;
}

@media only screen and (min-width: 600px) {
  .secondary-btn-active {
    background-color: var(--black);
    /* justify-content: right; */
    padding-left: 1vw;
  }

  .secondary-btn-active {
    background-color: var(--black);
    /* justify-content: right; */
    padding-left: 1vw;
  }
}

.background-color-white {
  background-color: var(--white);
}

.primary-btn-text {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter-Bold";
  display: flex;
  padding: 0px 20px;
  color: var(--white);
}

.secondary-btn-text {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter-Bold";
  display: flex;
  padding: 0px 20px;
  color: var(--black);
}

.secondary-btn-text-active {
  font-size: 14px;
  font-family: "Inter-Bold";
  color: var(--white);
}

.Img {
  width: 50px;
  height: 50px;
  border-radius: 30px;
}

.Img-24 {
  width: 24px;
  height: 24px;
  border-radius: 30px;
}

.onbHeart {
  height: 10vh;
  width: 13vh;
  top: 20px;
  left: 10px;
}

.flower_stem {
  height: 20vh;
  width: 5vh;
  right: 10px;
  top: 20px;
}

.flower_doodle {
  height: 6vh;
  width: 6vh;
  right: 60px;
  top: 25px;
}

.ob1_tree {
  height: 9vh;
  width: 9vh;
  right: 10px;
  bottom: 10px;
}

.ob1_arr1 {
  height: 4vh;
  width: 4vh;
  /* bottom: 0px; */
  right: -4px;
  transform: rotate(125deg);
  /* background-color: brown; */
}

.ob1_arr1_text-anim {
  transform: rotate(10deg);
}

.ob1_arr1_text {
  top: -45px;
  right: -30px;
  font-family: "Chilanka";
  font-size: 20px;
  line-height: 1.2;
}

.ob1_arrR {
  height: 7vh;
  width: 7vh;
  /* bottom: 0px; */
  left: -15px;
  bottom: -10px;
  transform: rotate(10deg);
}

.ob1_arr2_text_anim {
  transform: rotate(-25deg);
}

.ob1_arr2_text {
  bottom: 35px;
  left: -35px;
  font-family: "Caveat-Regular";
  font-size: 20px;
  line-height: 1.3;
  font-weight: 400;
  /* word-spacing: -10em; */
}

.mid-container {
  margin-bottom: 10vh;
}

.CustomTable {
  /* margin: 0px ; */
  border-spacing: 0;
}

.CustomTableBody {
  border-radius: 25px;
  overflow: hidden;
  /* Hide overflow caused by the border-radius */
  border: 2px solid black;
}

.TableRow {
  padding: 18px 0px;
  border-bottom: 2px solid black;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.TableRow.firstRow {
  /* border-top-left-radius: 50px;
  border-top-right-radius: 50px; */
}

.TableRow.lastRow {
  padding: 18px 0px;
  border-bottom: 0px solid black;
}

.TableData {
  padding: 0px 0px;
  font-family: "Inter-Regular";
  font-size: 14px;
  color: var(--black);
  align-items: center;
  padding: 0px 0px;
}

.inter-regular-14 {
  font-family: "Inter-Regular";
  font-size: 14px;
  color: var(--black);
  align-items: center;
}

.secondary-btn-icon {
  top: 8px;
  left: 5px;
}

.TextInput {
  /* width: 100%; */
  border-radius: 10px;
  /* padding: 17px 24px; */
  font-family: "Inter-Regular";
  color: var(--dark-gray);
  border-width: 1px;
  border-color: var(--black);
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 500;
  outline: none;
  background-color: var(--white);
  overflow: hidden;
}

.TextInputDropDown {
  /* width: 100%; */
  border-radius: 10px;
  /* padding: 17px 24px; */
  font-family: "Inter-Regular";
  color: var(--dark-gray);
  border-width: 1px;
  border-color: var(--black);
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 500;
  outline: none;
  background-color: var(--white);
}

.input-text-14-bold {
  font-size: 14px;
  font-family: "Inter-Bold";
  /* display: flex; */
  padding: 5px 0px;
  color: var(--black);
}

.input-text-12-bold {
  font-size: 14px;
  font-family: "Inter-Bold";
  /* display: flex; */
  padding: 5px 0px;
  color: var(--black);
}

.input-text-44-bold {
  font-size: 44px;
  font-family: "Inter-Bold";
  /* display: flex; */
  padding: 5px 0px;
  color: var(--black);
}

.term-text {
  font-size: 12px;
  font-family: "Inter-Regular";
  /* display: flex; */
  padding: 10px 0px;
  color: var(--black);
  text-decoration: underline;
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  /* Text color */
  font-size: 16px;
  user-select: none;
}

.checkbox-container input {
  display: none;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid var(--black);
  border-radius: 2px;
  background-color: white;
  margin-right: 10px;
}

.checkmark::after {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  /* Tick color */
  font-size: 18px;
  visibility: hidden;
}

.checkmark-round {
  border-radius: 12px;
}

.checkmark-round::after {
  background-color: var(--black);
  content: "";
}

.checkbox-container input:checked+.checkmark::after {
  visibility: visible;
}

.checkbox-container {
  cursor: pointer;
}

.checkmark-checked {
  background-color: var(--black);
}

/* .checkbox-container:hover .checkmark {
  background-color:  var(--black); 
} */

.pr10 {
  left: 1.9vw;
  /* background-color: aquamarine; */
}

.ModalContainer {
  text-align: center;
  margin: 10px;
  position: relative;
}

.OpenModalButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.ModalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  /* max-width: 680px; */

  /* width: 100%; */
  /* height: 100%; */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  justify-content: flex-end;
}

.ModalContent {
  background-color: var(--light-pink);
  padding: 20px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  /* width: 100%;
  height: 70%; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.ModalContent,
.modal-radius{
  /* width: 410px; */
  margin: 0 auto;
}

.modal-radius {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.modal-points-content {
  position: absolute;
  /* left: 20px;
  top: 50px;
  right: 20px;
  bottom: 80px; */
  left: 50%;
  top: 50%; 
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: var(--light-pink);
  border-radius: 5px;
  border-width: 3px;
  border-color: var(--black);
  z-index: 0;
}

.modal-mood-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%; 
  transform: translateY(-50%);
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--white);
  border-radius: 5px;
  z-index: 0;
  max-width: 420px;
}

.ModalHeader {
  text-align: right;
}

.CloseModalButton {
  cursor: pointer;
  font-size: 20px;
}

.ModalBody {
  padding: 20px 0;
}

.DropdownContainer {
  position: relative;
  display: flex;
  flex: 1;
}

.DropdownHeader {
  /* width: 100%; */
  display: flex;
  flex: 1;
  border-radius: 5px;

  /* padding: 17px 24px; */
  border-width: 2px;
  border-color: black;
  cursor: pointer;
  font-family: "Inter-Regular";
  background-color: var(--white);
  color: var(--dark-gray);
  box-shadow: 2px 4px 0px rgba(0, 0, 0, 1);
  font-size: 16px;
  outline: none;
}

.font-black {
  color: var(--black);
}

.DropdownOptions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  border-width: 2px;
  border-color: black;
  box-shadow: 1px 2px 0px rgba(0, 0, 0, 1);
  background-color: white;
  font-family: "Inter-Regular";
  max-height: 150px;
  /* Set your desired maximum height */
  overflow-y: auto;
  /* Enable vertical scrolling within options */
  /* border-top-width: 0px; */
  /* border-top-right-radius: 0px;
  border-top-left-radius: 0px; */
}

.selectOptions {
  background-color: white;
  font-family: "Inter-Regular";
  font-size: 16px;
}

.DropdownOption {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }
}

.DropdownContainer.open .DropdownHeader {
  border-radius: 5px 5px 0 0;
}

.DropdownContainer.open .DropdownOptions {
  border-radius: 5px 5px 5px 5px;
}

.bg-white {
  background-color: var(--white);
}

.BottomTabsContainer {
  width: 100%;
  /* right: 0; */
  max-width: 520px;
  position: fixed;
  bottom: 0;
  background-color: white;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
  border-top-color: var(--black);
  border-top-width: 2px;
  padding-bottom: env(safe-area-inset-bottom, 10px);
}

@media only screen and (min-width: 600px) {
  .BottomTabsContainer {
    width: 100%;
    /* right: 0; */
    max-width: 420px;
    position: fixed;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
    border-top-color: var(--black);
    border-top-width: 2px;
  }
}

.BottomTabsContent {
  display: flex;
  position: relative;
}

.BottomTab {
  display: flex;
  flex: 1;
  padding: 10px;
  /* padding-top: 20px; */
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  /* transition: background-color 0.3s; */
  position: relative;
  z-index: 10;

  align-items: center;
  justify-content: center;
}

.BottomTab.active {
  background-color: white;
}

.magnify::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 10;
  width: 14vw;
  height: 14vw;
  max-width: 70px;
  max-height: 70px;
  border-left: 2px solid var(--black);
  border-top: 2px solid var(--black);
  border-bottom: 0px;
  border-radius: 0px;
  border-top-left-radius: 14vh;
  border-bottom-right-radius: 14vh;
  background-color: var(--white);
  transform: translateY(-52%) rotate(45deg);
  /* transition: transform 1s , border-radius 1s; */
}

.home-frag-cont {
  border-width: 3px;
  border-color: var(--black);
  border-radius: 12px;
  /* width: 45vh; */
  /* width: 50vh; */
  /* overflow: hidden; */
}

.home-frag-img {
  height: 25vh;
  width: 43vh;
  max-width: 50vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.challange-frag-img-cont {
  margin-right: 0px;
}

.challange-frag-img {
  height: 25vh;
  width: 100%;
  /* max-width: 42vh; */
}

.home-frag-pic-img {
  height: 4vh;
  width: 4vh;
}

.home-frag-pic-backbtn {
  height: 3vh;
  width: 3vh;
}

.home-frag-pic-cal {
  height: 4vh;
  width: 4vh;
}

.home-frag-checkin {
  border-width: 3px;
  border-color: var(--black);
  border-radius: 12px;
  /* width: 90vw; */
}

.secondary-btn-round {
  border-radius: 100px;
  border: 2px solid var(--black);
  box-shadow: 1px 3px 0px 0px var(--black);
  background-color: var(--white);
  display: flex;
  /* min-height: 40px; */
  align-items: center;
  justify-content: center;
}

.header-u4u {
  height: 5.0vh;
  width: 10vh;
  /* right: 38vw; */
}

.header-jem {
  height: 37px;
  width: 31px;
}

.header-jem-sm {
  height: 3vh;
  width: 2.5vh;
}

.header-star {
  height: 4.5vh;
  width: 4.5vh;
}

.emoji-cont {
  background-color: var(--light-pink);
  padding: 4px 7px;
  border-radius: 10px;
  border-width: 2px;
  border-color: var(--white);
  /* width: 8vh; */
}

.emoji-cont-width {
  width: 9.5vh;
  height: auto;
  max-width: 100px;
}

.emoji {
  height: 5vh;
  width: 5vh;
  /* border-color: var(--black); */

  /* margin-bottom: 3px; */
}

.emoji-selected {
  border-width: 2px;
  border-color: var(--black);
}

.add {
  height: 1.5vh;
  width: 1.5vh;
  /* margin-bottom: 3px; */
}

.emoji-l {
  height: 8vh;
  width: 8vh;
}

.bg-light-pink {
  background-color: var(--light-pink);
}

.sora-semibold-24 {
  font-family: "Sora-SemiBold";
  font-size: 24px;
}

.sora-semibold-14 {
  font-family: "Sora-SemiBold";
  font-size: 14px;
}

.sora-regular-16 {
  font-family: "Sora-Regular";
  font-size: 16px;
}

.option-cont {
  border-width: 2px;
  border-color: var(--black);
  border-radius: 12px;
}

.orange-circle {
  height: 10vh;
  width: 10vh;
}

.moodring-card-image {
  height: 10vh;
  width: 10vh;
}

.moodring-mood-image {
  height: 16vh;
  width: 16vh;
}

.circle-icon {
  border-radius: 5vh;
  background-color: var(--white);
  top: 94px;
  /* right: 166px; */
}

.point-anim-cont {
  height: 68svh;
}

.moodring-card {
  border-width: 2px;
  border-color: var(--black);
  border-radius: 5px;
  height: 30vh;
  background-color: var(--light-purple);
}

.moodring-card-mood {
  border-radius: 10px;
  height: 30vh;
  background-color: var(--light-purple);
}

.grid-container {
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;  */
  /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin: 0px 20px; */
}

.step2-btn-mic {
  /* width: 17vh; */
  width: 34vw;
  /* background-color: gray */
}

.step2-btn-sm {
  /* width: 17vh; */
  width: 38vw;
  /* background-color: blueviolet; */
}

.step2-btn-md {
  /* width: 20vh; */
  width: 50vw;
  /* background-color: aqua; */
}

.step2-btn-lg {
  /* width: 24vh; */
  width: 54vw;
  /* background-color: antiquewhite; */
}

.stepfour-margin {
  padding-top: 32vw;
  padding-bottom: 5vh;
}

.pointTrackerCount {
  height: 7vh;
  width: 14vh !important;
  max-width: 14vh !important;
  margin-left: -15px;
  margin-top: -40px;
}

.emoji-modal {
  height: 80vh;
  padding-bottom: 0px;
}

.stepOnetext {
  position: absolute;
  top: 2vh;
  left: 3vh;
  padding: 1vh 2vh;
}

.stepBubbletext {
  position: absolute;
  top: 1vh;
  left: 3vh;
  padding: 1vh 2vh;
}

.width-85 {
  width: 85%;
}

@media only screen and (min-width: 600px) {
  .step-four-screen {
    margin-top: 1vh;
  }
}

.W30 {
  width: 35%;
}

.W40 {
  width: 42%;
}

.W50 {
  width: 48%;
}

.W60 {
  width: 56%;
}

.W65 {
  width: 63%;
}

/* select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

/* .select::after {
  content: "\25BC";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: blueviolet;
} */

.flex.py-2.mx-auto{ max-width: 100% !important;}
.h-svh .fixed.max-w-80{ padding-top: 86px; min-height: 480px; box-shadow: 0px 0px 5px #000; overflow: hidden; width: 95%; max-width: 420px; border-radius: 15px; height: auto; background: #ffd3f6; padding-bottom: 0px; padding-left: 0px; padding-right: 0px; }
.h-svh .bg-white.rounded-full{ background: none; font-size: 30px;}
.h-svh .fixed.max-w-80::after { width: 50%; margin-left: auto; margin-right: auto; content: "More Info"; display: block; position: absolute; left: 0; right: 0; color: #000; z-index: 9; top: 13px; text-align: center; font-size: 20px; font-weight: bold;}
.h-svh .fixed.max-w-80::before { width: 190%; background: #fff; height: 100%; content: ""; display: block; position: absolute; left: -45%; color: #000; z-index: -1; top: 52px; text-align: center; font-size: 20px; font-weight: bold; border-radius: 47%;}
.h-svh .fixed.max-w-80 p.text-sm{ background: #fff; padding-left: 1rem; font-size: 16px; line-height: 22px; padding-right: 1rem; padding-bottom: 30px; padding-top: 5px;}
.TextInput input{ width: 100%;}
.TextInput input[type="password"]{ padding-right: 38px;}
.flex.flex-col.h-svh.pb-5.mb-5{ margin-bottom: 0px;}
.chat-scrollbar .items-end p{ word-break: break-word; text-align: justify; }
.bday-pop.ModalContent{ max-width: 420px; width: 100%;}



@media(max-width:480px){
.relative.flex.flex-col.flex-1.h-svh.pt-3.pb-5.mb-5{ padding-bottom: 0px;}
.flex.h-svh .ModalOverlay .modal-radius.px-3.bg-white{ width: 100%; }
.emoji-modal .emoji-cont{ border-width:1px;}
.emoji-modal .emoji-cont .inter-text-9-semibold{ font-size: 8px; padding-top: 3px; }
.emoji-modal .emoji{ width: 4vh; height: 4vh;}
 }
 
 @media(max-height:600px){
.emoji-modal{ height: 70vh;}
 }