:root {
  --black: #000000;
  --brand-pink: #ea088c;
  --light-pink: #ffd3f6;
  --red: #e24330;
  --light-orange: #ff9728;
  --orange: #ffe0bf;
  --yellow: #f1f232;
  --green: #79e8b3;
  --light-green: #d7f8e8;
  --blue: #629ef8;
  --light-blue: #cee1ff;
  --blue: #a6bcfe;
  --lavender: #cf91ec;
  --light-purple: #f1def9;
  --purple: #864fcb;
  --raisin-black: #242325;
  --dark-gray: #888888;
  --timberwolf: #d6d6d4;
  --platinum: #ecece9;
  --white: #ffffff;
  --card2: #f0fbe6;
  --card1: #e1f9f9;
}

@font-face {
  font-family: "Chilanka";
  src: url("./assets/fonts/Chilanka-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Sora-Regular";
  src: url("./assets/fonts/Sora-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Sora-SemiBold";
  src: url("./assets/fonts/Sora-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "FjordOne-Regular";
  src: url("./assets/fonts/FjordOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "CherryBomb-Regular";
  src: url("./assets/fonts/CherryBombOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Caveat-Regular";
  src: url("./assets/fonts/Caveat-Regular.ttf") format("truetype");
}

/* .chilanka-regular {
  font-family: "Chilanka", cursive;
  font-weight: 400;
  font-style: normal;
} */

.App {
  /* height: 100vh; */
  /* width: 50%; */
  /* padding: 40px 40px; */
  /* display: flex; */
  /* flex: 1; */
  max-width: 520px;
  /* width: 100%; */
  /* flex-direction: column; */
  /* margin: 0 auto; */
  /* margin-bottom: 500px; */
  /* position: relative; */
}

@media only screen and (min-width: 600px) {
  .App {
    max-width: 420px;
  }
}

.element {
  /* height: env(safe-area-inset-top); */
  /* width: env(safe-area-inset-left); */
  /* margin: env(safe-area-inset-right); */
  /* padding-bottom: env(safe-area-inset-bottom, 20px);
  padding-top: env(safe-area-inset-top, 20px); */
}

.chat-scrollbar{
  height: calc(75% - 30px);
  padding-bottom: 1vh;
}
@media (min-width: 991px) {
  .chat-scrollbar {
    height:65vh;
  }
}
@media screen and (max-height: 900px) {
  .chat-scrollbar {
    height: 63vh;
  }
}

@media screen and (max-height: 800px) {
  .chat-scrollbar {
    height: 65%;
  }
}

@media screen and (max-height: 633px) {
  .chat-scrollbar {
    height: 50vh;
  }
}

.chat-scrollbar::-webkit-scrollbar {
  width: 5px;
}


.chat-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  @apply rounded-lg
}
 
/* Handle */
.chat-scrollbar::-webkit-scrollbar-thumb {
@apply bg-[#EA088C] rounded-lg
}
